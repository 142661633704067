import { useUserStore } from '@/stores/userStore';

export default defineNuxtRouteMiddleware((to: any, from: any) => {
    const { ensureDataIntegrity, endSession, tokenExists, isRouteAccessible, authDefaults } = useAuth();
    const userStore = useUserStore();

    if (!ensureDataIntegrity()) {
        endSession(false);
        return navigateTo(authDefaults.logoutRedirectTo);
    }

    if (!isRouteAccessible(to, userStore, tokenExists)) {
        if (!userStore.isAuthenticated)
            return navigateTo(authDefaults.unauthorizedRedirectTo);
        else
            return navigateTo(authDefaults.forbiddenRedirectTo);
    }
});